<script>
import { Pie } from 'vue-chartjs'

export default {
  mixins: [Pie],
  props: {
    chartdata: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>